<template>
  <page-heading header-text="Recipes" is-root />
  <div class="max-w-xl mx-auto gap-4 flex flex-col px-4">
    <cgn-link v-for="group in groups" :key="group.url" :to="`/recipes/${group.url}`" class="flex flex-row even:flex-row-reverse items-center text-center border rounded-md overflow-hidden">
      <div class="inline-block w-2/5 bg-gray-800">
        <cgn-lazy-image :image="group.image" class="w-full aspect-[4/3]" />
      </div>
      <div class="text-lg sm:text-2xl font-semibold flex-1">
        {{ group.name }}
      </div>
    </cgn-link>
  </div>
</template>

<script setup lang="ts">
import { gql } from 'graphql-request'
import type { CognitoImage } from '~cognito/models/Cognito/Image'
import { $axios } from '~cognito/plugins/axios'

const groups = ref<{
  name: string
  url: string
  image: CognitoImage
}[]>([])

onMounted(() => {
  $axios.graphql(gql`query GroupRecipes {
    cognitoGroups(model: "Recipe\\\\Recipe") {
      name
      url
      image(image_aspect: "4x3") {
        url
        width
        height
      }
    }
  }`).then((data: any) => {
    groups.value = data.cognitoGroups
  })
})
</script>
