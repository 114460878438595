<template>
  <div
    class="flex flex-col-reverse items-center justify-center gap-16 px-4 py-24 md:gap-28 md:px-44 md:py-20 lg:flex-row lg:p-24"
  >
    <div class="relative w-full pb-12 lg:pb-0 xl:w-1/2 xl:pt-24">
      <div class="relative">
        <div class="relative z-10">
          <div class>
            <h1 class="my-2 text-2xl font-bold text-gray-800 dark:text-white">
              Page not found
            </h1>
            <p
              class="my-2 text-gray-800 dark:text-white"
            >
              Please visit our homepage to get where you need to go.
            </p>
            <cgn-button color-brand extra-classes="px-8 py-[1rem]" class="pt-2" url="/">
              Take me home
            </cgn-button>
          </div>
        </div>
        <div class="absolute top-0 text-[12rem] font-bold leading-[11rem] text-white dark:text-darkbg-500 sm:left-20 sm:text-[15rem]">
          404
        </div>
      </div>
    </div>
  </div>
</template>

<route lang="yaml">
name: not-found
meta:
  layout: 404
</route>
