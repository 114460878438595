<template>
  <div class="flex items-center pb-3 pt-2 px-2">
    <router-link :to="props.headerLink" class="block text-center text-2xl font-semibold flex-1">
      {{ props.headerText }}
    </router-link>
    <cgn-link v-if="!isRoot" to="/">
      <i-heroicons-solid:home class="text-lg w-7 h-7" />
    </cgn-link>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  headerText: {
    type: String,
    required: true,
  },
  headerLink: {
    type: String,
    default: '/',
  },
  isRoot: {
    type: Boolean,
    default: false,
  },
})
</script>
